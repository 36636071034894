const en = {
  headerData: [
    {
      title: 'NEWS',
      url: '/news',
      children: []
    },
    {
      title: 'PEOPLE',
      url: '/team',
    },
    {
      title: 'RESEARCH',
      url: '/research',
    },
    {
      title: 'RESOURCES',
      url: '/resources',
    },
    {
      title: 'COURSE',
      url: '/course',
    },
    {
      title: 'PUBLICATIONS',
      url: '/publication',
    },
    {
      title: 'ALBUM',
      url: '/xiuxianhuodong',
    },
    {
      title: 'CONTACT',
      url: '/contact',
    }
  ],
  research: {
    intro: {
      title: 'Research content',
      content: ['Technology drives scientific development. With the advancement and application of new technologies such as high-throughput next-generation sequencing, high-precision quantitative mass spectrometry, high-resolution cryo-electron microscopy, and molecular imaging, biological research has entered a post-genomic era with a primary goal of discovering and interpreting whole-genome functional components, revealing the structure of super-complexes and complex molecular machines, as well as understanding genetic changes and disease relationships from system-wide component interactions and quantitative levels. The development of these "new" biology provides us with new perspectives and tools for solving traditional problems, and raises our understanding of the complexity of biological systems to a new level, but at the same time bringing more new and more challenging problems.',
        'We are aware of these issues, challenges and opportunities. We have also witnessed that these high-throughput, high-precision, high-resolution new technologies have accumulated and are accumulating a large amount of data. We foresee that the development of "new" biology will increasingly rely on technologies such as high performance computing and big data analytics to study biological problems from unprecedented depth, breadth and height. We believe that because of the complexity of biological systems, with the accumulation of data, machine intelligence will play an important role in the study of biological problems.',
        'The field of this research idea of our laboratory is the emerging structural systems biology. The laboratory combines research techniques such as structural biology, genomics, machine learning, and big data analysis to study major and cutting-edge biological problems. We use computing, especially machine learning, as our core tool and explore our conjecture through a unique experimental platform. In particular, the laboratory is also interested in developing and creating new computing and experimental techniques.']
    },
    others: [
      {
        title: '1、Biomacromolecules, especially RNA structure and its functional relationship',
        content: ['The sequence determines the structure and the structure determines the function. The central topics of structural information biology are how to predict the structure of biomacromolecules (protein, RNA and DNA) sequences accurately, how to compare and analyze the relationship between devices in the structure space, and how to predict its function from the structure.',
          'Structurally specific chemical modifications that block the ability of the reverse transcription process can be used to probe RNA structures. After modifying RNA, add the reverse transcriptase, and then using sequencing to obtain the site of the reverse transcription stop, which is the modification sit. Since the modification is structurally specific, we can infer the structural properties of the modification site accordingly. We developed a new method for the determination of intracellular transcriptome RNA structures (icSHAPE: in vivo click SHAPE) at the Howard Chang Laboratory in Stanford. This method uses a newly synthesized, cell permeable small molecule, NAI-N3. This molecule can selectively modify RNA according to the structural environment of the 2 OH group on the sugar ring. Because it has an additional azide side chain, the modified RNA molecule can be chemically coupled to a handle and purified, which greatly reduces sequencing background and improves structural detection accuracy. Our preliminary results in mouse embryonic stem cells demonstrate the superior signal-to-noise ratio and structural detection accuracy and validity of the new method, and reveal the relationship between RNA structure and function from the level of whole transcriptome.',
          'Our laboratory will develop more efficient algorithms and software to predict and model intracellular RNA molecular structures based on in vivo experimental information. Most of the current prediction methods are based on simulated folding, and the structural state within the cell cannot be obtained. Our research will reveal the structure of RNA molecules as they perform functions, and conformational changes in different states. Based on this, the laboratory will analyze the relationship between proteins, RNA and other structures as well as the properties of the structural space. In particular, we will develop effective prediction or discovery methods for protein functional sites, RNA functional motifs, etc., to further elucidate the principles and laws governing the structure of biological macromolecules.']
      },
      {
        title: '2、Biomacromolecular interaction network',
        content: [
          'The function of biological macromolecules (proteins, RNA, and DNA molecules) is achieved by interacting with other macromolecules. The patterns of these interactions are structurally conservative. Recently, we developed a methodology for accurately predicting genome-wide protein-protein interactions using the information of conserved patterns of interacting structures (PrePPI). We will develop the PrePPI prediction algorithm and apply it to the study of more and newer protein-protein interaction networks. For some special species and systems, we will work together to develop a new method for structural model optimization with experimental information (such as electron microscopy data) to finally reveal the evolutionary laws of dynamic interactions of protein interaction networks in different environments, as well as the fine structure of supercomplexes.',
          ' Similar structural modeling prediction methods can be applied to protein-RNA interaction prediction to improve accuracy and sensitivity. The difficulty is that our knowledge of protein-RNA interactions is very limited. We participated in the development of experimental techniques (ChIRP-MS) using oligonucleotide tile arrays and protein profiles to detect proteins interacting with specific RNAs. Unlike popular protein-centric approaches in CLIP experiments, ChIRP is RNA-centric and can be used to delve into the interactions and functions of certain RNAs (especially ncRNAs) that have important functions or are abnormal in disease.'
        ]
      },
      {
        title: '3、Long non-coding RNA system evolution and functional classification',
        content: ['   A growing number of functional studies have found that long non-coding RNAs (lncRNAs) are closely related to many biological processes, including participation in epigenetic regulation. In particular, in recent years, some studies have found that abnormal expression of lncRNA, mis-shearing or even single-base mutations are closely related to human diseases, including cancer.','Comparison and evolutionary analysis of sequences between species helps to understand sequence-function relationships, including identifying sequence segments or sequence features that are meaningful for lncRNA function, identifying homologous lncRNAs that perform the same function between species, and even grouping lncRNAs with similar functional mechanisms and so on. However, due to the low level of sequence conservation of lncRNA and incomplete annotation information between different species, It is very difficult to study Systematic evolution and functional of lncRNA.', 'In our previous work, we successfully identified a low sequence-consistent lncRNA ortholog roX in Drosophila based on evolutionary collinearity and motif distribution patterns in the sequence. The laboratory will develop this analytical approach, based on genome-based comparative analysis including more lncRNA features, combined with machine learning and evolutionary analysis, systematically study the evolution of lncRNA in vertebrate taxa and identify a number of potential orthologous lncRNAs. Our research aims to establish a relatively complete lncRNA analysis process and provide new clues for understanding the evolution of vertebrate lncRNA, especially the conserved originals in the sequence and their possible functional significance.']
      },
      {
        title: '4、RNA viral molecular mechanism',
        content: ['The genetic material of RNA viruses is RNA. RNA viruses have higher variability than DNA viruses because of the lack of error correction mechanisms in the replication process. RNA viruses include many common infectious disease viruses, such as flu, HIV, SARS, MERS, Ebola and most plant viruses. The ravages of these viruses have brought enormous and painful life and economic losses to human society. Our laboratory will combine the structural and molecular interaction networks of RNA and proteins to study the molecular mechanisms and possible therapeutic modalities of RNA virus invading cells and the resulting infectious diseases.']
      },
      {
        title: '5、Complex diseases and precision medicine',
        content: ['There is growing evidence that protein-protein interaction networks with structural information can help us understand the mechanisms of complex diseases with greater precision. Because of the structural information, we can predict the different consequences of different gene mutations at a higher resolution. Comparing a limited number of PDB protein complexes, the protein-protein and protein-RNA global interaction networks established by structural modeling methods will greatly enhance our ability to identify mutations that affect the structure and interaction of proteins. In particular, cancer genome research has found millions of cancer-related mutation sites. Our analysis shows that even though most of these studies use exon sequencing that focuses on protein coding regions, there are still more than a quarter of mutations either in non-coding regions or without coding changes. These recurrent mutations in the cancer genome cannot be understood at the protein level, but our research suggests that they may cause disease by altering the RNA structure and perturbing the protein-RNA interaction network.']
      }
    ]
  },
  team: {
    currentTeam: {
      leaders: {
        PI: {
          img: require('../assets/team/PI.png'),
          title: 'PI',
          simpTitle: 'PI',
          content: [{
            name: 'Qiangfeng Cliff Zhang',
            img: require('../assets/team/zhangqiangfeng.png'),
            pos: 'Ph.D Principal Investigator',
            disc: ['School of Life Sciences, Tsinghua University; Center for Life Sciences, Tsinghua-Peking University; Investigator, PhD supervisor',
              '2000: University of Science and Technology of China, School of the Gifted Young, Bachelor',
              '2006: University of Science and Technology of China, Computer Science, PhD',
              '2012: Columbia University, Biophysics, PhD'],
            email: 'qczhang@tsinghua.edu.cn'
          }]
        },
        Admin: {
          img: require('../assets/team/zhuli.png'),
          title: 'Administrative assistant',
          simpTitle: 'Admin',
          content: [{
            name: 'Hairong Chen',
            img: require('../assets/team/hairong.jpg'),
            pos: 'Administrative assistant',
            disc: ['Daily affairs management'],
            email: 'chenhair@mail.tsinghua.edu.cn'
          }]
        }
      },
      leaders2: {
        Postdoc: {
          img: require('../assets/team/boshihou.png'),
          title: 'Postdoc',
          simpTitle: 'Postdoc',
          content: [
            {
              img: require('../assets/team/boshihou/wangqianwen.png'),
              name: 'Xiwen Wang Ph.D',
              poser: '', //'Postdoctoral fellow of School of Life Sciences',
              disc: ['[1] An ultra low-input method for global RNA structure probing uncovers Regnase-1-mediated regulation in macrophages;','[2] RNA structure probing uncovers RNA structure-dependent biological functions;']
            },
            /*{
              img: require('../assets/team/boshihou/lijiaying.png'),
              name: 'Jiaying Li Ph.D',
              poser: '', //'Postdoctoral fellow of School of Life Sciences',
              disc: ['RNA Structure Detection, Functional Analysis']
            },*/
            // {
            //   img: require('../assets/team/doctor/sunlei.png'),
            //   name: 'Lei Sun Ph.D',
            //   poser: '', //'Postdoctoral fellow of School of Life Sciences',
            //   disc: ['[1] Advances and opportunities in RNA structure experimental determination and computational modeling;', '[2] Comparison of viral RNA–host protein interactomes across pathogenic RNA viruses informs rapid antiviral drug discovery for SARS-CoV-2;','[3] In vivo structural characterization of the SARS-CoV-2 RNA genome identifies host proteins vulnerable to repurposed drugs;','[4] Predicting dynamic cellular protein–RNA interactions by deep learning using in vivo RNA structures;','[5] RNA structure maps across mammalian cellular compartments;','[6] Integrative Analysis of Zika Virus Genome RNA Structure Reveals Critical Determinants of Viral Infectivity;','[7] Cryo-EM Structure of Human Dicer and Its Complexes with a Pre-miRNA Substrate;','[8] RNA Regulations and Functions Decoded by Transcriptome-wide RNA Structure Probing;']
            // },
            // {
            //   img: require('../assets/team/boshihou/guofengmin.jpg'),
            //   name: 'Fengmin Guo Ph.D',
            //   poser: '', //'Postdoctoral fellow of School of Life Sciences',
            //   disc: ['Chemical Probe for RNA Structure and Distribution']
            // },
            // {
            //   img: require('../assets/team/boshihou/zhaokang.jpg'),
            //   name: 'Kang Zhao Ph.D',
            //   poser: '', //'Postdoctoral fellow of School of Life Sciences',
            //   disc: ['RNA Structure Detection, Functional Analysis']
            // },
            {
              img: require('../assets/team/boshihou/feiyuhan.jpg'),
              name: 'Yuhan Fei Ph.D',
              poser: '', //'Postdoctoral fellow of School of Life Sciences',
              disc: ['[1] RNA Structural Dynamics Modulate EGFR-TKI Resistance Through Controlling YRDC Translation in NSCLC Cells;', '[2] Advances and opportunities in RNA structure experimental determination and computational modeling;']
            },
            {
              img: require('../assets/team/doctor/zhangjinsong.png'),
              name: 'Jinsong Zhang Ph.D',
              pos: '', //PhD graduate
              disc: ['[1] RNA structure determination: From 2D to 3D;','[2] Advances and opportunities in RNA structure experimental determination and computational modeling;', '[3] An ultra low-input method for global RNA structure probing uncovers Regnase-1-mediated regulation in macrophages;','[4] RNA structure probing reveals the structural basis of Dicer binding and cleavage;','[5] RNA structural dynamics regulate early embryogenesis through controlling transcriptome fate and function;']
            },
            {
              img: require('../assets/team/doctor/xukui.png'),
              name: 'Kui Xu Ph.D',
              pos: '', //PhD graduate
              disc: ['[1] PrismNet: predicting protein–RNA interaction using in vivo RNA structural information;','[2] Structural basis of membrane skeleton organization in red blood cells;','[3] CryoRes: Local Resolution Estimation of Cryo-EM Density Maps by Deep Learning;','[4] A deep learning method for recovering missing signals in transcriptome-wide RNA structure profiles from probing experiments;','[5] In vivo structural characterization of the SARS-CoV-2 RNA genome identifies host proteins vulnerable to repurposed drugs;','[6] Predicting dynamic cellular protein–RNA interactions by deep learning using in vivo RNA structures;','[7] Structure of the activated human minor spliceosome;','[8] RASP: an atlas of transcriptome-wide RNA secondary structure probing data;','[9] VRmol: an Integrative Web-Based Virtual Reality System to Explore Macromolecular Structure;','[10] SCALE method for single-cell ATAC-seq analysis via latent feature extraction;','[11] A²-Net: Molecular Structure Estimation from Cryo-EM Density Volumes;','[12] RISE: a database of RNA interactome from sequencing experiments;']
            },
            {
              img: require('../assets/team/doctor/huangwenze.png'),
              name: 'Wenze Huang Ph.D',
              pos: '', //PhD graduate
              disc: ['[1] PrismNet: predicting protein–RNA interaction using in vivo RNA structural information;','[2] Comparison of viral RNA–host protein interactomes across pathogenic RNA viruses informs rapid antiviral drug discovery for SARS-CoV-2;','[3] In vivo structural characterization of the SARS-CoV-2 RNA genome identifies host proteins vulnerable to repurposed drugs;','[4] Predicting dynamic cellular protein–RNA interactions by deep learning using in vivo RNA structures;','[5] RNA structure maps across mammalian cellular compartments;','[6] Integrative Analysis of Zika Virus Genome RNA Structure Reveals Critical Determinants of Viral Infectivity;']
            },
            {
              img: require('../assets/team/doctor/tanglei.png'),
              name: 'Lei Tang Ph.D',
              pos: '', //PhD graduate
              disc: ['[1] An ultra low-input method for global RNA structure probing uncovers Regnase-1-mediated regulation in macrophages;','[2] Predicting dynamic cellular protein–RNA interactions by deep learning using in vivo RNA structures;','[3] SCALE method for single-cell ATAC-seq analysis via latent feature extraction;','[4] RNA structure maps across mammalian cellular compartments;','[5] Integrative Analysis of Zika Virus Genome RNA Structure Reveals Critical Determinants of Viral Infectivity;']
            }

          ]
        },
        doctor: {
          img: require('../assets/team/doctor.png'),
          title: 'PhD Students',
          simpTitle: 'PhD Students',
          content: [
            // {
            //   img: require('../assets/team/doctor/yangrui.png'),
            //   name: 'Rui Yang',
            //   pos: 'PhD student',
            //   disc: ['[1] PrismNet: predicting protein–RNA interaction using in vivo RNA structural information;']
            // },
            // {
            //   img: require('../assets/team/doctor/tiankang.jpeg'),
            //   name: 'Kang Tian',
            //   pos: 'PhD student',
            //   disc: ['[1] Online single-cell data integration through projecting heterogeneous datasets into a common cell-embedding space;','[2] SCALE method for single-cell ATAC-seq analysis via latent feature extraction;']
            // },
            {
              img: require('../assets/team/doctor/dongzhuoer.jpeg'),
              name: 'Zhuoer Dong',
              pos: 'PhD student',
              disc: ['[1] CryoRes: Local Resolution Estimation of Cryo-EM Density Maps by Deep Learning;']
            },
            {
              img: require('../assets/team/benke/xujingle.png'),
              name: 'Jingle Xu',
              pos: 'PhD student',
              disc: ['[1] VRmol: an Integrative Web-Based Virtual Reality System to Explore Macromolecular Structure;']
            },
            // {
            //   img: require('../assets/team/doctor/liyuzhe.jpg'),
            //   name: 'Yuzhe Li',
            //   pos: 'PhD student',
            //   disc: ['[1] Online single-cell data integration through projecting heterogeneous datasets into a common cell-embedding space;','[2] The chromatin-remodeling enzyme Smarca5 regulates erythrocyte aggregation via Keap1-Nrf2 signaling;']
            // },
            {
              img: require('../assets/team/doctor/lizegang.jpg'),
              name: 'Zegang Li',
              pos: 'PhD student',
              disc: ['Deep Learning, Parallel Computing']
            },
            {
              img: require('../assets/team/doctor/huangyong.jpg'),
              name: 'Yong Huang',
              pos: 'PhD student',
              disc: ['Viral RNA Interaction, Genomic Data Analysis']
            },
            {
              img: require('../assets/team/doctor/zhujianghui.jpg'),
              name: 'Jianghui Zhu',
              pos: 'PhD student',
              disc: ['[1] PrismNet: predicting protein–RNA interaction using in vivo RNA structural information;','[2] Recent advances in RNA structurome;','[3] An ultra low-input method for global RNA structure probing uncovers Regnase-1-mediated regulation in macrophages;']
            },
            {
              img: require('../assets/team/doctor/wangpengfei.jpg'),
              name: 'Pengfei Wang',
              pos: 'PhD student',
              disc: ['RNA-targeting drug discovery']
            },
            {
                  img: require('../assets/team/doctor/daimuzhi.jpg'),
                  name: 'Muzhi Dai',
                  pos: 'PhD student',
                  disc: ['[1] CryoRes: Local Resolution Estimation of Cryo-EM Density Maps by Deep Learning;']
              },
            {
                  img: require('../assets/team/doctor/zhangjiasheng.jpg'),
                  name: 'Jiasheng Zhang',
                  pos: 'PhD student',
                  disc: ['RNA-targeting Drug Discovery']
              },
            {
                  img: require('../assets/team/doctor/ningweixi.jpg'),
                  name: 'Weixi Ning',
                  pos: 'PhD student',
                  disc: ['[1] Online single-cell data integration through projecting heterogeneous datasets into a common cell-embedding space;']
              },
            {
                  img: require('../assets/team/doctor/majianbo.jpg'),
                  name: 'Jianbo Ma',
                  pos: 'PhD student',
                  disc: ['RNA Design']
              },
            {
                  img: require('../assets/team/doctor/lizihan.jpeg'),
                  name: 'Zihan Dominic Li',
                  pos: 'PhD student',
                  disc: ['RNA-targeting drug discovery']
              },
            {
                  img: require('../assets/team/doctor/moukunting.jpeg'),
                  name: 'Kunting Mu',
                  pos: 'PhD student',
                  disc: ['Deep learning, RNA structure']
              },
            {
                  img: require('../assets/team/doctor/caizilin.jpeg'),
                  name: 'Zilin Cai',
                  pos: 'PhD student',
                  disc: ['RNA Design']
              }
          ]
        },
        /*undergraduate: {
          img: require('../assets/team/benke.png'),
          title: 'Undergraduate',
          simpTitle: 'Undergrad',
          content: [
              {
                  img: require('../assets/team/benke/huangkangping.jpg'),
                  name: 'Kangping Huang',
                  pos: 'Undergraduate',
                  disc: []
              },

          ]
        },*/
        
        /*assistant: {
          img: require('../assets/team/benke.png'),
          title: 'Research Assistant',
          simpTitle: 'Assistant',
          content: [
            {
              img: require('../assets/team/assistant/xuyiran.png'),
              name: 'Yiran Xu',
              pos: 'Research Assistant',
              disc: ['[1] PrismNet: predicting protein–RNA interaction using in vivo RNA structural information;']
            },
            {
              img: require('../assets/team/assistant/xuyiran.png'),
              name: 'Chongyang Wang',
              pos: 'Research Assistant',
              disc: ['RNA structure probing']
            }
          ]
        }*/
      }
    },

    oldTeam: {
      manager: {
        title: 'Former assistant',
        content: [
          {
            img: require('../assets/team/wangrui.png'),
            name: 'Rui Wang',
            pos: 'Lab Manager',
            disc: ['Laboratory establishment, daily affairs management', 'email: wangrui@phalab.org']
          },
          {
            img: require('../assets/team/weiwendi.png'),
            name: 'Wendi Wei',
            pos: 'Lab Manager',
            disc: ['Laboratory establishment, daily affairs management', 'email: weiwendi@tsinghua.edu.cn'],
          }
        ]
      },
      
      postdoc: {
        title: 'Postdoc',
        content: [
          {
            img: require('../assets/team/boshihou/xiong.png'),
            name: 'Tuanlin Xiong Ph.D',
            pos: 'Postdoc stand out', //'Postdoctoral fellow of School of Life Sciences',
            disc: ['Non-coding RNA Evolution','2021-08']
          },
          {
            img: require('../assets/team/boshihou/zhangshaojun.png'),
            name: 'Shaojun Zhang Ph.D',
            pos: 'Postdoc stand out', //'Postdoctoral fellow of School of Life Sciences',
            disc: ['RNA-protein interactions','2022-08']
          },
          {
            img: require('../assets/team/boshihou/zhaokang.jpg'),
            name: 'Kang Zhao Ph.D',
            pos: 'Postdoc stand out', //PhD graduate
            disc: ['RNA Interactions and Functional ','2022-10']
          },
          {
            img: require('../assets/team/doctor/sunlei.png'),
            name: 'Lei Sun Ph.D',
            pos: 'Postdoc stand out',
            disc: ['RNA Structure Detection and Related', 'Functional Analysis', '2022-11']

          },
          {
            img: require('../assets/team/boshihou/guofengmin.jpg'),
            name: 'Fengmin Guo Ph.D',
            pos: 'Postdoc stand out', //'Postdoctoral fellow of School of Life Sciences',
            disc: ['Chemical Probe for RNA Structure and Distribution', '2024-12']
          },
        ]
      },      
      graduate: {
        title: 'Graduates',
        content: [
          {
            img: require('../assets/team/doctor/sunlei.png'),
            name: 'Lei Sun',
            pos: 'PhD graduate',
            disc: ['RNA Structure Detection and Related', 'Functional Analysis', '2019-10']
          },
          {
            img: require('../assets/team/doctor/piaomeiling.png'),
            name: 'Meiling Piao',
            pos: 'PhD graduate',
            disc: ['RNA Structure Detection and Related', 'Functional Analysis', '2020-10']
          },
          {
            img: require('../assets/team/doctor/gongjing.png'),
            name: 'Jing Gong',
            pos: 'PhD graduate',
            disc: ['RNA Structures and Interactions', '2020-10']
          },
          {
            img: require('../assets/team/doctor/xionglei.png'),
            name: 'Lei Xiong',
            pos: 'PhD graduate',
            disc: ['Single Cell', '2020-10']
          },
          {
            img: require('../assets/team/doctor/zhangjinsong.png'),
            name: 'Jinsong Zhang',
            pos: 'PhD graduate',
            disc: ['RNA Structure Detection and Related', 'Functional Analysis','2021-06']
          },
          {
            img: require('../assets/team/doctor/xukui.png'),
            name: 'Kui Xu',
            pos: 'PhD graduate',
            disc: ['Machine Learning, 3D Image Modeling','2021-06']
          },
          {
            img: require('../assets/team/doctor/lipan.png'),
            name: 'Pan Li',
            pos: 'PhD graduate',
            disc: ['Genomic Data Analysis, Algorithm Design','2021-06']
          },
          {
            img: require('../assets/team/doctor/huangwenze.png'),
            name: 'Wenze Huang',
            pos: 'PhD graduate',
            disc: ['Non-coding RNA Evolution','2021-10']
            },
            {
              img: require('../assets/team/doctor/weiyifan.png'),
              name: 'Yifan Wei',
              pos: 'PhD graduate',
              disc: ['RNA Library Construction and Viral ','RNA Research','2022-06']
            },
            {
              img: require('../assets/team/doctor/tanglei.png'),
              name: 'Lei Tang',
              pos: 'PhD graduate', //PhD graduate
              disc: ['RNA Interactions and Functional ','Single-cell','2022-06']
            },
            {
              img: require('../assets/team/doctor/shaoqiuyan.png'),
              name: 'Yanqiu Shao',
              pos: 'PhD graduate', //PhD graduate
              disc: ['RNA Interactions and Functional ','2022-10']
            },
            {
              img: require('../assets/team/doctor/yangrui.png'),
              name: 'Rui Yang',
              pos: 'PhD graduate',
              disc: ['Viral RNA structure and RBP','2023-12'] 
            },
            {
              img: require('../assets/team/doctor/tiankang.jpeg'),
              name: 'Kang Tian',
              pos: 'PhD graduate',
              disc: ['Single-cell', '2024-05']
            },
            {
              img: require('../assets/team/doctor/liyuzhe.jpg'),
              name: 'Yuzhe Li',
              pos: 'PhD graduate',
              disc: ['Single-cell spatial transcriptomics', '2024-05']
            },
            // {
            //   img: require('../assets/team/boshihou/zhaokang.jpg'),
            //   name: 'Kang Zhao Ph.D',
            //   pos: 'Postdoc', //PhD graduate
            //   disc: ['RNA Interactions and Functional ','2022-10']
            // }
        ]
      },       
      assistant: {
        title: 'Research Scientist',
        content: [
          {
            img: require('../assets/team/premember/qinyan.jpg'),
            name: 'Yan Qing',
            pos: 'Research assistant',
          },
          {
            img: require('../assets/team/premember/liruihua.jpg'),
            name: 'Ruihua Li',
            pos: 'Research assistant',
          },
          {
            img: require('../assets/team/premember/hange.png'),
            name: 'Ge Han',
            pos: 'Research assistant',
          },
          {
              img: require('../assets/team/doctor/qianxingyang.png'),
              name: 'Xingyang Qian',
              pos: 'Research assistant',
              //pos: 'PhD student',
              //disc: ['lncRNA Structure and Function']
          },
          {
            img: require('../assets/team/assistant/xuyiran.png'),
            name: 'Yiran Xu',
            pos: 'Research Assistant',
            // disc: ['[1] PrismNet: predicting protein–RNA interaction using in vivo RNA structural information;']
          }
      ]},
      benke: {
        title: 'Bachelor',
        content: [
          {
              img: require('../assets/team/benke/wangpengfei.jpg'),
              name: 'Pengfei Wang',
              pos: 'Undergraduate',
              disc: []
          },
          {
              img: require('../assets/team/benke/zhouxiaolin.jpg'),
              name: 'Xiaolin Zhou',
              pos: 'Undergraduate',
              disc: []
          },
          {
              img: require('../assets/team/benke/dingjiawei.png'),
              name: 'Jiawei Ding',
              pos: 'Undergraduate',
              disc: []
          },
          {
            img: require('../assets/team/benke/hewanwen.png'),
            name: 'Wanlin He',
            pos: 'Undergraduate',
          },
          {
              img: require('../assets/team/benke/nichongming.png'),
              name: 'Chongming Ni',
              pos: 'Undergraduate',
              disc: []
          },
          {
            img: require('../assets/team/premember/juyanyan.jpg'),
            name: 'Yanyan Ju',
            pos: 'Undergraduate',
            //disc: ['2016-2017 Research Scientist', 'now MIT']
          },
          {
            img: require('../assets/team/premember/panhanying.jpg'),
            name: 'Hanying Pan',
            pos: 'Undergraduate',
            //disc: ['2016-2017 Research Scientist', 'now MIT']
          },
          {
            img: require('../assets/team/premember/liyongge.jpg'),
            name: 'Yongge Li',
            pos: 'Undergraduate',
            //disc: ['2016-2017 Research Scientist', 'now MIT']
          },
          {
            img: require('../assets/team/premember/tianyuan.jpg'),
            name: 'Yuan Tian',
            pos: 'Undergraduate',
            //disc: ['2016-2017 Research Scientist', 'now MIT']
          },
          {
            img: require('../assets/team/premember/xujialu.jpg'),
            name: 'Jialu Xu',
            pos: 'Undergraduate',
            //disc: ['2016-2017 Research Scientist', 'now MIT']
          },
          {
            img: require('../assets/team/premember/lengjingze.png'),
            name: 'Jingze Leng',
            pos: 'Undergraduate',
            //disc: ['2016-2017 Research Scientist', 'now MIT']
          },
          {
            img: require('../assets/team/premember/xiaoyi.jpg'),
            name: 'Yi Xiao',
            pos: 'Undergraduate',
            //disc: ['2016-2017 Research Scientist', 'now MIT']
          },
          {
            img: require('../assets/team/premember/zhangyn.jpg'),
            name: 'Yuning Zhang',
            pos: 'Undergraduate',
            //disc: ['2016-2017 Research Scientist', 'now MIT']
          },
          {
            img: require('../assets/team/premember/xujun.png'),
            name: 'Jun Xu',
            pos: 'Undergraduate',
            //disc: ['2016-2017 Research Scientist', 'now MIT']
          }
        ]
      },
      visit: {
        title: 'Visiting student',
        content: [
          {
            img: require('../assets/team/premember/yuanyitian.png'),
            name: 'Yitian Yuan',
            pos: 'Visiting student',
            //disc: ['Johns Hopkins University']
          },
        ]
      }
    },
    tabData: ['PI', 'Admin', 'Postdoc', 'PhD Students', 'Undergrad', 'Assistant', 'alumni']
  },
  home: {
    title: {
      title: 'ABOUT US'
      // disc: 'scrutinized、factualistic、and scientific ethos'
    },
    content: [
      {
        disc: 'Our laboratory is affiliated to the School of Life Sciences of Tsinghua University, and is also the part of the Center for Life Sciences of Tsinghua and Peking University, Beijing Advanced Innovation Center for Structural Biology, Bioinformatics Division and Center for Synthetic and Systems Biology.'
      },
      {
        disc: 'Our research focuses on cross-disciplinary fields such as structural biology, genomics, machine learning, and big data analytics. Our main Research Interests is to develop and use a combination of computational and experimental methods to interpret the structural and functional relationships of biological macromolecules (eg, proteins, RNA, DNA) and to reconstruct their interaction networks, and discover the mechanisms and possible treatments for complex diseases that are associated with structural changes in proteins and RNA and abnormalities in macromolecular interactions( including cancer and infectious diseases) by combining structural biology and systems biology. We have unique protein and RNA structure modeling and next-generation sequencing-based RNA structure measurements, and high-throughput RNA-protein interaction detection technologies, as well as powerful computational and experimental platforms to advance our research.'
      },
      {
        disc: ['Main interest:', '1. Analyze the structure of biological macromolecules, in particular, detect RNA secondary structure using high-throughput deep sequencing and use machine learning methods to predict and model RNA secondary structure; explain the structural and functional relationship on this basis, especially machine learning methods that effectively predict or discover protein functional sites, RNA functional motifs, etc.;', '2. Develop methods based on high-throughput experiments and structural modeling to reconstruct biological macromolecules such as protein-protein, RNA-RNA, and protein-RNA interaction networks;', '3. In particular, Combining sequence and structural analysis to study its phylogenetic and functional classification for long non-coding RNA (lncRNA);', '4. Combining the structural and molecular interaction networks of RNA and proteins to study the molecular mechanisms and possible therapeutic approaches of RNA virus invading cells and causing infectious diseases;', '5. Discover the mechanisms by which genomic mutations cause protein and RNA folding errors and interaction network disorders, as well as the diagnosis, prognosis, and possible treatments for complex diseases, including cancer, in combination with structural and network development.']
      }
    ]
  },
  news: ['news', 'activities'],
  contact: {
    title: 'CONTACT US',
    info: [
      ['Laboratory Address: Biomedical Building, Tsinghua University,Beijing, China A109','Laboratory Zip Code: 100084', 'Laboratory Phone: 86-10-627-92786'],
      ['Office Address: Medical Science Building, Tsinghua University,Beijing A-116','Office Zip Code: 100084', 'Office Phone: 86-627-95823','Email： qczhang@tsinghua.edu.cn'],
      ['Assistant: Hairong Chen','Email： chenhair@mail.tsinghua.edu.cn']
    ],
    links: {
      title: 'Links',
      content: [
        {
          title: 'Center for Life Sciences',
          url: 'http://www.cls.edu.cn/'
        },
        {
          title: 'School of Life Sciences, Tsinghua University',
          url: 'http://life.tsinghua.edu.cn/'
        },
        {
          title: 'ICSB',
          url: 'http://www.icsb.tsinghua.edu.cn/'
        }
      ]
    }
  },
  xiuxian: ['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', 'Birthday'],
  resource: ['Online Server', 'Protocol', 'Datasets', 'Internal wiki']
}

export default en
